<div
  [ngClass]="{
    'justify-content-center': justify === 'center',
    'justify-content-between': justify === 'between',
    'justify-content-around': justify === 'around',
    'justify-content-end': justify === 'end',
    'justify-content-start': justify === 'start',
  }"
  class="d-flex align-items-center"
  style="gap: 1em">
  <app-streak-info [count]="currentStreakCount" [state]="state" [type]="currentStreakCount > bestStreakCount ? 'new_current' : 'current'"></app-streak-info>
  <app-streak-info [count]="bestStreakCount" [state]="state" [type]="currentStreakCount > bestStreakCount ? 'old_best' : 'best'"></app-streak-info>
</div>
