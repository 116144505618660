import { Component, Inject, OnInit } from '@angular/core';
import { ON_STREAK_PHRASES, StreakService } from '../../service/streak.service';
import { StreakInfo } from '../../model/streak.model';
import moment from 'moment';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilsService } from '../../../../core/utils-service/utils.service';
import { COMPONENT_STATE, ComponentState, GhostFragmentComponent } from '@futura/futura-ui/ghost';
import { TranslocoPipe } from '@jsverse/transloco';
import { ProgressBarChartComponent } from '../../../charts/progress-bar-chart/component/progress-bar-chart/progress-bar-chart.component';
import { FutCardComponent } from '../../../components/fut-card/fut-card.component';
import { StreakDisplayComponent } from '../streak-display/streak-display.component';
import { BaseDialogComponent } from '@futura/futura-ui/dialog';

@Component({
  selector: 'app-streak-dialog',
  templateUrl: './streak-dialog.component.html',
  styleUrls: ['./streak-dialog.component.scss'],
  standalone: true,
  imports: [BaseDialogComponent, StreakDisplayComponent, FutCardComponent, GhostFragmentComponent, ProgressBarChartComponent, TranslocoPipe],
})
export class StreakDialogComponent implements OnInit {
  public state: ComponentState = COMPONENT_STATE.LOADING;
  public streakInfo?: StreakInfo;

  public maxStreak?: number;
  public currentStreak?: number;

  public streakState?: 'on_streak' | 'not_on_streak';

  public hasTestToday: boolean = false;

  public readonly streakPhrase = ON_STREAK_PHRASES[Math.floor(Math.random() * ON_STREAK_PHRASES.length)] as string;

  constructor(
    private streakService: StreakService,
    @Inject(MAT_DIALOG_DATA) public data: StreakInfo | undefined,
    private dialogRef: MatDialogRef<StreakDialogComponent>,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    if (this.data) this.loadStreak(this.data);
    else this.streakService.get().subscribe(streak => this.loadStreak(streak));
  }

  loadStreak(streak: StreakInfo) {
    this.streakInfo = streak;
    this.state = COMPONENT_STATE.NONE;

    const { bestStreakCount, currentStreakCount } = this.streakService.loadStreakInfo(streak);
    this.maxStreak = bestStreakCount;
    this.currentStreak = currentStreakCount;

    console.log(moment(streak.current_streak?.end));
    this.hasTestToday = !!(streak.current_streak && moment(streak.current_streak.end).isSame(moment(), 'day'));

    this.streakState = this.currentStreak > this.maxStreak ? 'on_streak' : 'not_on_streak';
  }

  goSimulator() {
    this.utilsService.gotoHomePage();
    this.dialogRef.close();
  }
}
